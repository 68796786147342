// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_SignUpIn",
    resetPassword: "B2C_1_ResetPassword",
    editProfile: "B2C_1_EditProfile",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://cygnusb2c.b2clogin.com/cygnusb2c.onmicrosoft.com/B2C_1_SignUpIn",
    },
    resetPassword: {
      authority:
        "https://cygnusb2c.b2clogin.com/cygnusb2c.onmicrosoft.com/B2C_1_ResetPassword",
    },
    editProfile: {
      authority:
        "https://cygnusb2c.b2clogin.com/cygnusb2c.onmicrosoft.com/B2C_1_EditProfile",
    },
  },
  authorityDomain: "cygnusb2c.b2clogin.com",
};

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: "e1cc9536-26a4-46b1-a678-d4f2dcc8bb0e",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    loginRequest:
      process.env.NODE_ENV === "production"
        ? "https://cygnuscues.com/dashboard"
        : "http://localhost:4200/dashboard",
    redirectUri:
      process.env.NODE_ENV === "production"
        ? "https://cygnuscues.com/dashboard"
        : "http://localhost:4200/dashboard",
    postLogoutRedirectUri:
      process.env.NODE_ENV === "production"
        ? "https://cygnuscues.com/"
        : "http://localhost:4200/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
  scopes: ["openid", "profile"],
};

export const forgotPasswordRequest = {
  authority:
    "https://cygnusb2c.b2clogin.com/cygnusb2c.onmicrosoft.com/B2C_1_ResetPassword",
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig = {
  scopes: ["https://cygnusb2c.onmicrosoft.com/api/Account.Read"],
  uri: "https://sfc.cygnuss.com",
};
