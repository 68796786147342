import { useMsal } from "@azure/msal-react";
import { Button } from "uikit-react";
import { loginRequest } from "../authConfig";

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  return (
    <Button onClick={() => handleLogin("redirect")} key="loginRedirect">
      Sign in
    </Button>
  );
};
