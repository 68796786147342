export const Dashboard = () => {
  return (
    <>
      <div className="uk-flex uk-flex-center uk-flex-middle uk-height-viewport uk-margin-remove-bottom">
        <div className="uk-width-1-2@s">
          <div className="uk-card uk-card-default uk-card-body uk-text-center">
            <h1 className="uk-card-title uk-text-primary uk-text-bold">
              Welcome to Cygnus
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};
