import { useMsal } from "@azure/msal-react";
import { Button } from "uikit-react";

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance.logoutPopup();
    } else if (loginType === "redirect") {
      instance.logoutRedirect();
    }
  };

  return (
    <Button onClick={() => handleLogin("redirect")} key="loginRedirect">
      Sign out
    </Button>
  );
};
