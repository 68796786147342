import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { MainLayout } from "../layout/MainLayout";
import { UserLayout } from "../layout/UserLayout";
import { TradingProducts } from "../ui-components/TradingProducts";
import { Dashboard } from "./Dashboard";

function Home() {
  return (
    <>
      <AuthenticatedTemplate>
        <UserLayout children={<Dashboard />}></UserLayout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <MainLayout children={<TradingProducts />}></MainLayout>
      </UnauthenticatedTemplate>
    </>
  );
}

export default Home;
